var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dis_flex flex_dir_col bg_col_fff bor_rad_4 p_16"},[_c('div',{staticClass:"dis_flex ali_it_cen"},[_c('div',{staticClass:"flex_1 font_16 font_bold"},[_vm._v(" "+_vm._s(_vm.item.transferNickName)+" ")]),_c('div',{class:`font_12 ${
        _vm.item.transferType == 1
          ? 'col_0A82E6 bg_col_EBF6FF'
          : 'col_66BB6A bg_col_E6FAE7'
      } bor_rad_4 p_4_8`},[_vm._v(" "+_vm._s(_vm.item.transferType==1?"支出":"收入")+" ")])]),_c('div',{staticClass:"m_t_8"}),_vm._l(([
      _vm.item.transferType == 1
        ? {
            label: '支出金额：',
            value: _vm.item.amount,
          }
        : {
            label: '收入金额：',
            value: _vm.item.amount,
          },
      {
        label: '对方简称：',
        value: `${_vm.item.collectNickName || ''}`,
      },
      {
        label: '备注：',
        value: `${_vm.item.remark || ''}`,
      },
      {
            label: '转账时间：',
            value: _vm.item.gmtCreate
              ? _vm.moment(new Date(_vm.item.gmtCreate.replace(/-/g, '/'))).format(
                  'YYYY-MM-DD'
                )
              : '-',
      }
    ].filter((it) => it)),function(item,index){return _c('div',{key:index,staticClass:"dis_flex font_14 m_t_8"},[_c('div',{staticClass:"flex_1 col_666 white_space_nowrap"},[_vm._v(_vm._s(item.label))]),_c('div',[_vm._v(_vm._s(item.value))])])}),_c('div',{staticClass:"dis_flex ju_con_flex_end m_t_16"},[(_vm.item.voucherUrl)?_c('div',{staticClass:"font_14 col_0A82E6 bor_solid_1_0A82E6 l_h_100_per bor_rad_4 p_6_12",on:{"click":function($event){return _vm.showUrl(_vm.item.voucherUrl)}}},[_vm._v(" 查看凭证 ")]):_vm._e(),_c('van-popup',{model:{value:(_vm.shareDialogShow),callback:function ($$v) {_vm.shareDialogShow=$$v},expression:"shareDialogShow"}},[_c('div',{staticClass:"w_88_vw"},[_c('van-swipe',[_c('van-swipe-item',[_c('img',{staticClass:"dis_flex",attrs:{"src":_vm.showImageUrl},on:{"click":function($event){_vm.shareDialogShow = false}}})])],1)],1)])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }