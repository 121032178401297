<template>
  <div class="dis_flex flex_dir_col bg_col_fff bor_rad_4 p_16">
    <div class="dis_flex ali_it_cen">
      <div class="flex_1 font_16 font_bold">
        {{ item.transferNickName }}
      </div>
      <div
        :class="`font_12 ${
          item.transferType == 1
            ? 'col_0A82E6 bg_col_EBF6FF'
            : 'col_66BB6A bg_col_E6FAE7'
        } bor_rad_4 p_4_8`"
      >
        {{ item.transferType==1?"支出":"收入" }}
      </div>
    </div>

    <!-- 留白 -->
    <div class="m_t_8"></div>

    <!-- 属性 -->
    <div
      v-for="(item, index) in [
        item.transferType == 1
          ? {
              label: '支出金额：',
              value: item.amount,
            }
          : {
              label: '收入金额：',
              value: item.amount,
            },
        {
          label: '对方简称：',
          value: `${item.collectNickName || ''}`,
        },
        {
          label: '备注：',
          value: `${item.remark || ''}`,
        },
        {
              label: '转账时间：',
              value: item.gmtCreate
                ? moment(new Date(item.gmtCreate.replace(/-/g, '/'))).format(
                    'YYYY-MM-DD'
                  )
                : '-',
        }
      ].filter((it) => it)"
      :key="index"
      class="dis_flex font_14 m_t_8"
    >
      <div class="flex_1 col_666 white_space_nowrap">{{ item.label }}</div>
      <div>{{ item.value }}</div>
    </div>

    <!-- 操作 -->
    <div class="dis_flex ju_con_flex_end m_t_16">
      <div
        class="
          font_14
          col_0A82E6
          bor_solid_1_0A82E6
          l_h_100_per
          bor_rad_4
          p_6_12
        "
        v-if="item.voucherUrl"
        @click="showUrl(item.voucherUrl)"
      >
        查看凭证
      </div>


      <!-- 分享图片弹窗 -->
      <van-popup v-model="shareDialogShow">
        <div class="w_88_vw">
          <van-swipe>
            <van-swipe-item >
              <img
                class="dis_flex"
                :src="showImageUrl"
                @click="shareDialogShow = false"
              />
            </van-swipe-item>
          </van-swipe>
        </div>

        <!--  -->
      </van-popup>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
const moment = window.moment;

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      shareDialogShow:false,
      showImageUrl:""
    };
  },
  computed: {},
  created() {},
  methods: {
    moment,


    showUrl(item){
      this.showImageUrl=item;
      this.shareDialogShow = true;
    }

    //
  },
};
</script>

<style lang="scss" scoped>
</style>
